import AppToast from "@/helpers/toast-methods";

export const downloadingMixin = {
  methods: {
    async getFile(hash, format = "pdf", isResume = true) {
      try {
        const fileInfo = {
          hash: hash,
          type: format,
        };

        const arrayBufferFile = isResume
          ? await this.$store.dispatch(
              "resumeCreation/downloadResume",
              fileInfo
            )
          : await this.$store.dispatch(
              "coverLetterCreation/downloadLetter",
              fileInfo
            );

        return new Blob([arrayBufferFile]);
      } catch (e) {
        AppToast.toastError(`There was an error downloading the file.`);
        return null;
      }
    },

    async getFileName(hash, format = "pdf", isResume = true) {
      const { first_name, last_name } = isResume
        ? await this.$store.dispatch("resumeCreation/getResume", hash)
        : await this.$store.dispatch(
            "coverLetterCreation/getCoverLetter",
            hash
          );

      return (
        (isResume ? "CV_" : "Cover_Letter_") +
        `${first_name}_${last_name}.${format}`
      );
    },

    initialisationDownloadFile(fileBlob, fileName) {
      if (!fileBlob && !fileName) return;

      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(fileBlob);
      link.download = fileName;
      link.click();
    },
  },
};
