<template>
  <div class="final-step container">
    <div class="row">
      <div class="col-xl-6">
        <div class="section-header final-step__header">
          <h1 class="section-header__title">
            Perfect! Your <span>Cover Letter</span> finished
          </h1>

          <p class="section-header__text">
            Congratulations! You are done with your cover letter. Double-check
            all the information you have provided before downloading. If you
            want to make any changes, you may go back to the editor.
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-5 col-xl-3 order-md-2">
        <button
          v-if="hideDoctype"
          type="button"
          class="download-btn"
          @click.prevent="downloadLetter"
        >
          <span class="download-btn__icon">
            <AppIcon componentName="CloudIcon" />
          </span>

          <span class="download-btn__title">Download Cover Letter</span>
        </button>

        <DownloadButton
          v-if="!hideDoctype"
          :loading="isDownloadingLetter"
          @download="downloadLetter"
          title="Download Cover Letter"
        />

        <FinalControls
          class="final-controls"
          @dashboard="goToDashboard"
          @print="print"
          @mail="openModal"
          @edit="editLetter"
        />
      </div>

      <div class="col-md col-xl-9 order-md-1">
        <PdfViewer :src="previewPDF" v-if="showPDF"> </PdfViewer>
        <transition name="fade">
          <div
            v-if="previewPDF == '' || previewPDF == null"
            class="preview-loader"
          >
            <AppButtonLoader :size="100" color="#4874e6" />
          </div>
        </transition>
      </div>
    </div>

    <AppModal :isOpenModal="isOpenModal" width="636px" @close="closeModal">
      <SendViaEmailModal @sendEmail="mailLetter" title="Cover Letter" />
    </AppModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import printJS from "print-js";

import {
  AUTH_NAMES,
  BROWS_TEMPLATE_NAMES,
  SUBSCRIBE_STEPS_NAMES,
  TEMPLATE_EDITING_NAMES,
} from "@/helpers/rout-constants";
import LetterHash from "@/helpers/cover-letter-hash";
import AppToast from "@/helpers/toast-methods";
import CompletedTemplate from "@/helpers/completed-template-hash";
import { downloadingMixin } from "@/mixins/DownloadingMixin";

import DownloadButton from "@/components/ui/DownloadButton";
import AppButtonLoader from "@/components/ui/AppEmbedLoader";
import FinalControls from "@/components/creation/FinalStepControls";
import SendViaEmailModal from "@/components/dashboard/SendViaEmailModal";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import CookieHelper from "@/helpers/cookie-helper";
import PdfViewer from "@/components/PdfViewer";

export default {
  name: "StepFinalLetterCreation",
  mixins: [downloadingMixin, mixpanelMixin],

  components: {
    DownloadButton,
    FinalControls,
    SendViaEmailModal,
    AppButtonLoader,
    PdfViewer,
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      isSubscribed: "user/isSubscribed",
      letter: "coverLetterCreation/getCoverLetter",
      previewAll: "coverLetterCreation/getCoverLetterPreviewAll",
      previewPDF: "coverLetterCreation/getCoverLetterPreviewPDF",
      isLoadingPreviewLetter: "coverLetterCreation/isLoadingPreview",
    }),
  },

  data() {
    return {
      isDownloadingLetter: false,
      isOpenModal: false,
      loading: false,
      letterHash: null,
      hideDoctype: false,
      showPDF: false,
    };
  },

  methods: {
    redirectUnsubscribed() {
      if (!this.isSubscribed) {
        this.$router.push({ name: SUBSCRIBE_STEPS_NAMES.SUBSCRIBE_PLANS });
        return false;
      }

      return true;
    },

    redirectUnregisteredIn() {
      if (!this.isLoggedIn) {
        this.$router.push({ name: AUTH_NAMES.REGISTRATION });

        return false;
      }

      return true;
    },

    async downloadLetter(format) {
      if (!this.redirectUnregisteredIn()) return;
      if (!this.redirectUnsubscribed()) return;

      this.isDownloadingLetter = true;

      AppToast.toastSuccess("The file will be uploaded to your PC soon...");

      const fileBlob = await this.getFile(this.letterHash, format, false);

      const fileName = await this.getFileName(this.letterHash, format, false);

      this.initialisationDownloadFile(fileBlob, fileName);

      this.isDownloadingLetter = false;
    },

    async print() {
      if (!this.redirectUnregisteredIn()) return;
      if (!this.redirectUnsubscribed()) return;

      AppToast.toastSuccess("The file will be sent to print soon...");

      const fileBlob = await this.getFile(this.letterHash, "pdf", false);

      printJS(URL.createObjectURL(fileBlob));
    },

    async openModal() {
      if (!this.redirectUnregisteredIn()) return;
      if (!this.redirectUnsubscribed()) return;

      this.isOpenModal = true;
    },

    closeModal() {
      this.isOpenModal = false;
    },

    async mailLetter(emailData) {
      if (!this.redirectUnregisteredIn()) return;
      if (!this.redirectUnsubscribed()) return;

      await this.$store.dispatch("loader/pending");
      try {
        const formData = new FormData();
        formData.append("hash", this.letterHash);
        formData.append("from", emailData.from);
        formData.append("to", emailData.to);
        formData.append("subject", emailData.subject);
        if (emailData.cc_emails) {
          emailData.cc_emails.forEach((email, idx) =>
            formData.append(`cc_emails[${idx}]`, email)
          );
        }
        if (emailData.attachments) {
          emailData.attachments.forEach((file, idx) =>
            formData.append(`attachments[${idx}]`, file)
          );
        }
        formData.append("text", emailData.text);
        await this.$store.dispatch("userDashboard/sendLetterByEmail", formData);
      } catch (err) {
        console.error(err);
      }

      this.closeModal();
      await this.$store.dispatch("loader/done");
    },

    editLetter() {
      if (!this.redirectUnregisteredIn()) return;
      this.$router.push({
        name: TEMPLATE_EDITING_NAMES.COVER_LETTER_EDITING,
        query: { hash: this.letterHash },
        params: {
          duplicate: true,
        },
      });
    },
    goToDashboard() {
      if (!this.redirectUnregisteredIn()) return;
      this.$router.push("/dashboard");
    },
  },

  async created() {
    this.letterHash = this.$route?.query?.uncompletedTemplate;

    if (!this.letterHash) {
      await this.$router.push({
        name: BROWS_TEMPLATE_NAMES.BROWS_LETTERS,
      });
      AppToast.toastError("We can not found your cover letter...");
      return;
    }

    this.mixpanelTrack("V2-FINALIZELETTER");

    CompletedTemplate.set({ hash: this.letterHash, type: "letter" });
    LetterHash.clear();

    await this.$store.dispatch("loader/pending");

    let flagHideDoctype = CookieHelper.getFlag("hide_doctype", "");

    if (flagHideDoctype == "1") {
      if (this.isSubscribed) {
        this.hideDoctype = false;
      } else {
        this.hideDoctype = true;
      }
    }

    try {
      await this.$store.dispatch(
        "coverLetterCreation/getCoverLetter",
        this.letterHash
      );

      await this.$store.dispatch(
        "coverLetterCreation/getCoverLetterPreviewAll",
        this.letterHash
      );
    } catch (err) {
      console.error(err);
    }

    if (this.previewPDF != "") {
      this.showPDF = true;
    }

    //prevent right click
    document.addEventListener("contextmenu", (event) => event.preventDefault());

    await this.$store.dispatch("loader/done");
  },

  async beforeMount() {
    await this.$store.dispatch("loader/done");
  },

  async beforeDestroy() {
    await this.$store.dispatch(
      "coverLetterCreation/cleanCoverLetter",
      this.letterHash
    );
  },
};
</script>

<style lang="scss" scoped>
::v-deep .section-header {
  &__title {
    font-size: 32px;

    span {
      color: $primary;
    }
  }

  &__text {
    font-size: 14px;
    color: $light-gray;
    margin-top: 8px;
  }
}

.final-step {
  padding-top: 40px;
  padding-bottom: 94px;

  &__header {
    margin-bottom: 40px;
  }
}

.resume-preview {
  padding-top: 1054/816 * 100%;
  background-color: #fff;
  position: relative;
  width: 100%;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.final-controls {
  margin: 24px 0;
}

.preview-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.8);
  backdrop-filter: blur(7px);
}

.download-btn {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  background-color: $primary;
  overflow: hidden;
  position: relative;
  padding: 0;

  &__title {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    flex-grow: 1;
    margin-left: -10px;
  }

  &__icon {
    height: 100%;
    width: 55px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: #fff;
      width: 24px;
      height: 24px;
    }

    &--arrow {
      svg {
        width: 13px;
        height: 8px;
      }
    }
  }
  &:active {
    background-color: #416fe5;
  }
}
</style>
