<template>
  <div class="email-modal">
    <h2 class="email-modal__title">Email {{ title }}</h2>
    <form @submit.prevent="sendEmail">
      <div class="row">
        <div class="col-sm-6 email-modal__field">
          <AppField
            label="From"
            :value="emailData.from"
            @input="setField($event, 'from')"
            :isInvalid="$v.emailData.from.$error"
            required
          >
            <template v-slot:error>
              <p v-if="!$v.emailData.from.required">This field is required</p>
            </template>
          </AppField>
        </div>
        <div class="col-sm-6 email-modal__field">
          <AppField
            label="To"
            :value="emailData.to"
            @input="setField($event, 'to')"
            :isInvalid="$v.emailData.to.$error"
            required
          >
            <template v-slot:error>
              <p v-if="!$v.emailData.to.required">This field is required</p>
              <p v-if="!$v.emailData.to.email">Incorrect email format</p>
            </template>
          </AppField>
        </div>

        <div class="col-12 email-modal__field">
          <AppField
            label="Subject"
            :value="emailData.subject"
            @input="setField($event, 'subject')"
            :isInvalid="$v.emailData.subject.$error"
            required
          >
            <template v-slot:error>
              <p v-if="!$v.emailData.subject.required">
                This field is required
              </p>
            </template>
          </AppField>
        </div>
        <div class="col-12 email-modal__field">
          <AppTextEditor
            label="Text"
            required
            :value="emailData.text"
            @input="setField($event, 'text')"
            :isInvalid="$v.emailData.text.$error"
          >
            <template v-slot:error>
              <p v-if="$v.emailData.text.minLength">
                Text must be at least
                {{ $v.emailData.text.$params.minLength.min }} characters
              </p>
            </template>
          </AppTextEditor>
        </div>

        <div class="col-12 email-modal__field">
          <AppFieldTags label="Cc" @input="setField($event, 'cc_emails')" />
        </div>

        <div class="col-12 email-modal__field">
          <AppDropzone
            title="Upload samples of your work"
            text=".pdf, .doc, .docx, .ppt, .pptx, .jpg, or .png; max 2 mb"
            :docTypes="types"
            @uploadFiles="uploadFiles"
            @removeFile="removeFile"
          />
        </div>
        <div class="col-12 email-modal__field">
          <AppButton
            className="primary"
            title="Send Email"
            class="email-modal__btn"
            type="submit"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import AppTextEditor from "@/components/ui/AppTextEditor";
import AppDropzone from "@/components/ui/AppDropzone";
import AppFieldTags from "@/components/ui/AppFieldTags";
import { email, minLength, required } from "vuelidate/lib/validators";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { mapGetters } from "vuex";

export default {
  name: "SendViaEmailModal",
  components: {
    AppTextEditor,
    AppDropzone,
    AppFieldTags,
  },
  props: {
    title: {
      type: String,
      default: "Resume",
    },
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  data() {
    return {
      emailData: {
        from: null,
        to: null,
        cc_emails: null,
        text: null,
        subject: null,
        attachments: null,
      },
      types: ["pdf", "doc", "docx", "ppt", "pptx", "jpg", "jpeg", "png"],
    };
  },
  validations: {
    emailData: {
      from: {
        required,
      },
      to: {
        email,
        required,
      },
      text: {
        required,
        minLength: minLength(1),
      },
      subject: {
        required,
      },
    },
  },
  methods: {
    setField(fieldData, fieldName) {
      this.emailData[fieldName] = fieldData;
    },

    uploadFiles(files) {
      this.emailData.attachments = Array.from(files);
    },

    removeFile(id) {
      this.emailData.attachments.splice(id, 1);
    },

    sendEmail() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$emit("sendEmail", this.emailData);
    },
  },

  created() {
    this.emailData.from = `${this.user?.first_name || ""} ${
      this.user?.last_name || ""
    }`;
  },
};
</script>

<style scoped lang="scss">
.email-modal {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px;
  padding-left: 15px;
  max-height: calc(90vh - 100px);

  &::-webkit-scrollbar {
    width: 2px;
    background-color: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 8px;
    outline: none;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &__title {
    font-size: 24px;
    line-height: 130%;
    margin-bottom: 24px;
  }

  &__field {
    padding: 0 10px;
  }

  .editor-wrap,
  .dropzone {
    margin-bottom: 24px;
  }

  .dropzone {
    height: auto;
  }

  &__btn {
    width: 100%;
  }
}

::v-deep .field__input {
  padding: 10px 10px 10px 16px;
}

::v-deep .quill-editor {
  min-height: 177px;

  @media (max-width: map_get($grid-breakpoints, lg)) {
    min-height: 100px;
  }
}

::v-deep .editor-wrap .quill-editor .ql-container {
  height: 90px;
}

::v-deep .editor-wrap .quill-editor .ql-container .ql-editor {
  min-height: 100%;
}

::v-deep .dropzone-preview {
  height: 158px;

  @media (max-width: map_get($grid-breakpoints, lg)) {
    height: 100%;
  }
}

::v-deep .btn {
  @media (max-width: map_get($grid-breakpoints, sm)) {
    min-width: 100%;
  }
}
</style>
