<template>
  <div class="download" v-click-outside="closeDD">
    <button
      type="button"
      class="download-btn"
      :class="{ 'download-btn--opened': isOpenDD }"
      :disabled="loading"
      @click.prevent="toggleDD"
    >
      <span class="download-btn__icon">
        <AppIcon componentName="CloudIcon" />
      </span>

      <span class="download-btn__title">{{ title }}</span>

      <span class="download-btn__icon download-btn__icon--arrow">
        <AppIcon componentName="ChevronIcon" class="download-btn__icon" />
      </span>

      <transition name="fade">
        <span v-if="loading" class="loading-musk">
          <AppEmbedLoader />
        </span>
      </transition>
    </button>

    <transition name="fade">
      <div v-if="isOpenDD" class="download-dd">
        <AppIcon
          componentName="TriangleSimpleIcon"
          class="download-dd__arrow"
        />

        <button
          type="button"
          class="download-dd-btn"
          @click.prevent="download('pdf')"
        >
          <AppIcon componentName="FilePdfIcon" class="download-dd-btn__icon" />
          <p class="download-dd-btn__label">.pdf</p>
        </button>

        <button
          type="button"
          class="download-dd-btn"
          @click.prevent="download('docx')"
        >
          <AppIcon componentName="FileDocIcon" class="download-dd-btn__icon" />
          <p class="download-dd-btn__label">.doc</p>
        </button>

        <button
          type="button"
          class="download-dd-btn"
          @click.prevent="download('txt')"
        >
          <AppIcon componentName="FileTxtIcon" class="download-dd-btn__icon" />
          <p class="download-dd-btn__label">.txt</p>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import AppEmbedLoader from "./AppEmbedLoader";

export default {
  name: "DownloadButton",
  components: {
    AppEmbedLoader,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Download Resume",
    },
  },

  data() {
    return {
      isOpenDD: false,
    };
  },

  methods: {
    toggleDD() {
      this.isOpenDD = !this.isOpenDD;
    },

    closeDD() {
      this.isOpenDD = false;
    },

    download(format) {
      this.$emit("download", format);
      this.closeDD();
    },
  },
};
</script>

<style lang="scss" scoped>
.download {
  width: 100%;
  position: relative;
  z-index: 2;
}

.download-btn {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  background-color: $primary;
  overflow: hidden;
  position: relative;
  padding: 0;

  &__title {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    flex-grow: 1;
  }

  &__icon {
    height: 100%;
    width: 55px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: #fff;
      width: 24px;
      height: 24px;
    }

    &--arrow {
      svg {
        width: 13px;
        height: 8px;
      }
    }
  }
  &:active {
    background-color: #416fe5;
  }
}

.download-dd {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary;
  padding: 32px;
  margin-top: 14px;
  border-radius: 8px;

  &__arrow {
    width: 31px;
    height: 25px;
    fill: $primary;
    position: absolute;
    right: 8px;
    top: 0;
    transform: translateY(-50%);
  }
}

.download-dd-btn {
  padding: 10px 16px;

  &__icon {
    width: 40px;
    height: 40px;
    fill: #fff;
  }

  &__label {
    margin-top: 8px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }

  &:hover {
    background-color: #527eef;
    border-radius: 8px;
  }
}

.loading-musk {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c5d2f5;
}
</style>
