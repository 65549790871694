<template>
  <div class="pdf-viewer">
    <canvas ref="canvas"></canvas>
    <div v-if="showPrevButton" @click.prevent="prevPage" class="prev-page">
      &lt;
    </div>
    <div v-if="showNextButton" @click.prevent="nextPage" class="next-page">
      &gt;
    </div>

    <div v-if="showPageDisplay" class="page-display">
      Page {{ pdfCurrentPage }} of {{ pdfTotalPages }}
    </div>
  </div>
</template>

<script>
import PDFJS from "pdfjs-dist";
import $ from "jquery";

export default {
  name: "PdfViewer",

  props: {
    src: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      pdfData: "",
      pdfTotalPages: 0,
      pdfCurrentPage: 1,
      showPrevButton: false,
      showNextButton: false,
      showPageDisplay: false,
    };
  },

  async mounted() {
    console.log("src:", this.src);
    if (this.src != "" && this.src != null) {
      this.pdfData = await PDFJS.getDocument(this.src);
      this.pdfTotalPages = this.pdfData.numPages;
      this.displayPage(1);
      this.displayPagerButton();
    } else {
      console.log("PDF Source not found");
    }
  },

  methods: {
    async displayPage(pageNumber) {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");

      this.pdfData.getPage(pageNumber).then(function (page) {
        const scale = 2;
        const viewport = page.getViewport({ scale: scale });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        page.render(renderContext);
      });
    },
    async prevPage() {
      this.pdfCurrentPage = this.pdfCurrentPage - 1;
      this.displayPage(this.pdfCurrentPage);
      this.displayPagerButton();
    },
    async nextPage() {
      this.pdfCurrentPage = this.pdfCurrentPage + 1;
      this.displayPage(this.pdfCurrentPage);
      this.displayPagerButton();
    },
    displayPagerButton() {
      this.showNextButton = false;
      this.showPrevButton = false;

      if (this.pdfCurrentPage < this.pdfTotalPages) {
        this.showNextButton = true;
      }
      if (this.pdfCurrentPage > 1) {
        this.showPrevButton = true;
      }

      if (this.pdfTotalPages == 1) {
        this.showNextButton = false;
        this.showPrevButton = false;
      }
      if (this.pdfTotalPages > 1) {
        this.showPageDisplay = true;
      }
    },
  },
};
</script>

<style>
.pdf-viewer {
  width: 100%;
  height: 100%;
}

canvas {
  width: 100%;
  height: 100%;
}

.prev-page {
  background-color: #2d6ccb;
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 4px 9px;
  color: #fff;
  font-size: 27px;
  cursor: pointer;
  position: absolute;
  top: 45%;
  border-radius: 50px;
  left: 25px;
}

.next-page {
  background-color: #2d6ccb;
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 4px 10px;
  color: #fff;
  font-size: 27px;
  cursor: pointer;
  position: absolute;
  top: 45%;
  border-radius: 50px;
  right: 25px;
}

.page-display {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #8c8f96;
}
</style>
