<template>
  <div class="controls">
    <button
      type="button"
      class="controls__item"
      @click.prevent="emitEvent('dashboard')"
    >
      <AppIcon componentName="HomeIcon" />
    </button>

    <button
      type="button"
      class="controls__item"
      @click.prevent="emitEvent('print')"
    >
      <AppIcon componentName="PrintIcon" />
    </button>

    <button
      type="button"
      class="controls__item"
      @click.prevent="emitEvent('mail')"
    >
      <AppIcon componentName="MailIcon" />
    </button>

    <button
      type="button"
      class="controls__item"
      @click.prevent="emitEvent('edit')"
    >
      <AppIcon componentName="EditIcon" />
    </button>
  </div>
</template>

<script>
export default {
  name: "FinalControls",

  methods: {
    addBlock() {
      if (this.disabled) return;

      this.$emit("addBlock");
    },

    emitEvent(eventName) {
      this.$emit(eventName);
    },
  },
};
</script>

<style lang="scss" scoped>
.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__item {
    height: 56px;
    width: 56px;
    border: 1px solid #d6d8dc;
    border-radius: 8px;

    svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background-color: $primary;
      border-color: $primary;

      svg {
        fill: #fff;
      }
    }
  }
}
</style>
