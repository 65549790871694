<template>
  <div
    class="tags-input-field"
    :class="{
      'tags-input-field--focused': isFocus,
      'tags-input-field--required': required,
      'tags-input-field--error': inputInvalid,
    }"
  >
    <label class="tags-input-field__label">
      {{ label }}
      <sup v-if="required">*</sup>
    </label>
    <vue-tags-input
      v-model="tag"
      :tags="tags"
      @tags-changed="onSaveTag"
      @adding-duplicate="onDuplicate"
      @before-adding-tag="onChange"
      @max-tags-reached="onTagsLimitReached"
      :max-tags="4"
      placeholder=""
      :add-on-key="separators"
      @focus="onFocus"
      @blur="onBlur"
      :validation="validateTag"
    />
    <transition name="fade">
      <div
        v-if="inputInvalid"
        class="error"
        @click.prevent="toggleErrorMessage"
        v-click-outside="closeErrorMessage"
      >
        <button type="button" class="error__btn">
          <AppIcon componentName="ErrorIcon" />
        </button>

        <transition name="fade">
          <div class="error__message" v-if="isVisibleErrorMessage">
            <slot name="error">
              <p v-if="emailInvalid">Incorrect email format</p>
              <p v-if="duplicatedTag">Email is duplicated</p>
              <p v-if="isLimitReached">You can send only 4 CC</p>
            </slot>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import { email } from "vuelidate/lib/validators";

export default {
  name: "AppFieldTags",
  components: {
    VueTagsInput,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
    },
    errorText: {
      type: String,
    },
    isInvalid: {
      type: Boolean,
    },
  },
  data() {
    return {
      tag: "",
      tags: [],
      separators: [32, 13, ","],
      emailInvalid: false,
      duplicatedTag: false,
      isLimitReached: false,
      isVisibleErrorMessage: false,
      isFocused: false,
    };
  },
  computed: {
    validateTag() {
      return [
        {
          classes: " ",
          rule: () => {
            return !this.$v.tag.email;
          },
          disableAdd: true,
        },
      ];
    },
    isFocus() {
      return !!this.tags.length || this.tag || this.isFocused;
    },
    inputInvalid() {
      return (
        this.emailInvalid ||
        this.isInvalid ||
        this.duplicatedTag ||
        this.isLimitReached
      );
    },
  },
  validations: {
    tag: {
      email,
    },
  },
  methods: {
    onSaveTag(newTags) {
      this.tags = newTags;
      this.duplicatedTag = this.isLimitReached = this.emailInvalid = false;
      this.$emit(
        "input",
        this.tags.map((tag) => tag.text)
      );
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
      if (!this.$v.tag.email && this.tag) this.emailInvalid = true;
      if (!this.tag) this.emailInvalid = false;
    },
    onDuplicate() {
      this.duplicatedTag = true;
    },
    onTagsLimitReached() {
      this.isLimitReached = true;
    },
    toggleErrorMessage() {
      this.isVisibleErrorMessage = !this.isVisibleErrorMessage;
    },
    closeErrorMessage() {
      this.isVisibleErrorMessage = false;
    },
    onChange(tag) {
      if (!this.$v.tag.email) this.emailInvalid = true;
      tag.addTag();
    },
  },
};
</script>

<style scoped lang="scss">
.tags-input-field {
  position: relative;

  &__label {
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    padding: 0 3px;
    color: $light-gray;
    z-index: 2;
    line-height: 80%;
    background-color: #fff;

    sup {
      font-size: 12px;
      line-height: 16px;
      transition: all 0.3s ease;
    }
  }

  &--focused {
    .tags-input-field__label {
      font-size: 13px;
      color: $body-color;
      top: 0;
      cursor: text;
    }
  }

  &--error {
    .vue-tags-input.vue-tags-input {
      ::v-deep .ti-input {
        border: 2px solid $red;
        color: $red;
      }
    }

    .tags-input-field__label {
      color: $red;
    }
  }
}

.vue-tags-input.vue-tags-input {
  max-width: 100%;
  margin-bottom: 24px;

  ::v-deep .ti-input {
    display: block;
    width: 100%;
    border: 1px solid #d6d8dc;
    border-radius: 8px;
    min-height: 56px;
    font-size: 16px;
    position: relative;
    z-index: 1;
    padding-right: 16px;

    @media (max-width: map_get($grid-breakpoints, lg)) {
      padding: 10px 5px;
    }
  }

  ::v-deep .ti-tags {
    max-height: 130px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
      background-color: #f1f1f1;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #4874e6;
      border-radius: 8px;
      outline: none;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  ::v-deep .ti-new-tag-input {
    min-width: 100px;
    min-height: 40px;
  }

  ::v-deep .ti-tag {
    z-index: 3;
    background: $primary;
    border-radius: 8px;
    font-size: 12px;
    line-height: 130%;
    padding: 8px 16px;
    max-height: 32px;
  }
  ::v-deep .ti-tags {
    align-items: center;
  }
}

::v-deep .error {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    svg {
      width: 20px;
      height: 20px;
      fill: #e26262;
    }
  }

  &__message {
    padding: 12px;
    background-color: #ffe8e8;
    border-radius: 8px;
    position: absolute;
    right: 10px;
    top: 100%;
    width: 210px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: $red;
    z-index: 5;
  }
}
</style>
